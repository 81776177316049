import { useContext } from 'react';

import { CurrencyContext } from './CurrencyProvider';

export const useCurrency = () => {
    const currencyValue = useContext(CurrencyContext);

    if (!currencyValue) {
        throw new Error('useCurrency must be used within a CurrencyProvider');
    }

    return currencyValue;
};
