'use client';

import cx from 'classnames';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useLocale } from '../../../../../core/i18n/Locale';
import { Button } from '../../../../../design-system/components/form/button/Button';
import { IconAmericanExpress } from '../../../../../design-system/components/icons/AmericanExpress';
import { IconMasterCard } from '../../../../../design-system/components/icons/Mastercard';
import { IconVisa } from '../../../../../design-system/components/icons/Visa';
import { useCommitmentsLinks } from '../../../../../shared/hooks/useCommitmentsLinks';
import { useFeatureFlag } from '../../../../../shared/hooks/useFeatureFlag';
import { useUrlGenerator } from '../../../../../shared/hooks/useUrlGenerator';

import linkStyle from '../FooterLinkList.module.css';

import aboutStyle from './FooterLinksAbout.module.css';

export function FooterLinksAbout() {
    const { generatePath, generatePathMayNotExist, generateBrandUrl } = useUrlGenerator();
    const { hasFeature } = useFeatureFlag();
    const { formatMessage } = useIntl();
    const { locale } = useLocale();
    const { impactReport } = useCommitmentsLinks();

    const cookiesPolicyUrl = generatePathMayNotExist('cookies_policy');
    const onCookiesPreferencesClick = useCallback(() => {
        if (window.Didomi) {
            window.Didomi.preferences.show();
        }
    }, []);

    return (
        <>
            <span className={cx(linkStyle.linkTitle, 'font-m-extrabold')}>
                {formatMessage({ id: 'global.footer.links.about' })}
            </span>

            <ul className="font-body-regular">
                {!hasFeature('who_we_are_footer_link_in_contact') ? (
                    <li>
                        <span
                            className={linkStyle.link}
                            onClick={() => {
                                window.location.assign(generateBrandUrl('about_us'));
                            }}
                        >
                            {formatMessage({
                                id: 'global.footer.links.whoWeAre',
                            })}
                        </span>
                    </li>
                ) : null}

                <li>
                    <span
                        className={linkStyle.link}
                        onClick={() => {
                            window.location.assign(generatePath('legals'));
                        }}
                    >
                        {formatMessage({
                            id: 'global.footer.links.legals',
                        })}
                    </span>
                </li>

                {cookiesPolicyUrl !== null ? (
                    <li>
                        <span
                            className={linkStyle.link}
                            onClick={() => {
                                window.location.assign(cookiesPolicyUrl);
                            }}
                        >
                            {formatMessage({
                                id: 'global.footer.links.cookiesPolicy',
                            })}
                        </span>
                    </li>
                ) : null}

                <li>
                    <Button onClick={onCookiesPreferencesClick} className={linkStyle.link}>
                        {formatMessage({
                            id: 'global.footer.links.cookiesSettings',
                        })}
                    </Button>
                </li>

                <li>
                    <span
                        className={linkStyle.link}
                        onClick={() => {
                            window.location.assign(generatePath('cgu'));
                        }}
                    >
                        {formatMessage({
                            id: 'global.footer.links.cgu',
                        })}
                    </span>
                </li>

                {hasFeature('confidentiality_footer') ? (
                    <li>
                        <span
                            className={linkStyle.link}
                            onClick={() => {
                                window.location.assign(generatePath('confidentiality'));
                            }}
                        >
                            {formatMessage({
                                id: 'global.footer.links.confidentiality',
                            })}
                        </span>
                    </li>
                ) : null}

                {hasFeature('responsible') ? (
                    <li>
                        <span
                            className={linkStyle.link}
                            onClick={() => {
                                window.location.assign(generateBrandUrl('better_trips'));
                            }}
                        >
                            {formatMessage({
                                id: 'global.footer.links.responsibleTourism',
                            })}
                        </span>
                    </li>
                ) : null}

                {locale === 'fr-FR' ? (
                    <li>
                        <span
                            className={linkStyle.link}
                            onClick={() => {
                                window.open(impactReport.url, '_blank', 'noopener');
                            }}
                        >
                            {impactReport.label}
                        </span>
                    </li>
                ) : null}

                {hasFeature('guarantees_footer') ? (
                    <li>
                        <span
                            className={linkStyle.link}
                            onClick={() => {
                                window.location.assign(generatePath('guarantees'));
                            }}
                        >
                            {formatMessage({
                                id: 'global.footer.links.guarantees',
                            })}
                        </span>
                    </li>
                ) : null}

                <li>
                    <a className={linkStyle.link} href={generatePath('sitemap')}>
                        {formatMessage({
                            id: 'global.footer.links.sitemap',
                        })}
                    </a>
                </li>

                {hasFeature('footer_payment_methods') ? (
                    <li>
                        <span>{formatMessage({ id: 'global.footer.links.payment' })}</span>
                        <div className={aboutStyle.paymentLogosWrapper}>
                            <IconVisa className={aboutStyle.paymentLogo} />
                            <IconMasterCard className={aboutStyle.paymentLogo} />
                            <IconAmericanExpress className={aboutStyle.paymentLogo} />
                        </div>
                    </li>
                ) : null}
            </ul>
        </>
    );
}
