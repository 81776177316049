'use client';

import cx from 'classnames';
import { useIntl } from 'react-intl';

import { useFeatureFlag } from '../../../../../shared/hooks/useFeatureFlag';
import { useUrlGenerator } from '../../../../../shared/hooks/useUrlGenerator';
import { useCurrentSite } from '../../../../../shared/providers/currentSite/useCurrentSite';

import linkStyle from '../FooterLinkList.module.css';

export function FooterLinksContact() {
    const { currentSite } = useCurrentSite();
    const { generatePath, generateBrandUrl } = useUrlGenerator();
    const { hasFeature } = useFeatureFlag();
    const { formatMessage } = useIntl();

    return (
        <>
            <span className={cx(linkStyle.linkTitle, 'font-m-extrabold')}>
                {formatMessage({ id: 'global.footer.links.contact' })}
            </span>

            <ul className="font-body-regular">
                {hasFeature('who_we_are_footer_link_in_contact') ? (
                    <li>
                        <span
                            className={linkStyle.link}
                            onClick={() => {
                                window.location.assign(generateBrandUrl('about_us'));
                            }}
                        >
                            {formatMessage({
                                id: 'global.footer.links.whoWeAre',
                            })}
                        </span>
                    </li>
                ) : null}

                {currentSite.pages.faq.url ? (
                    <li>
                        <span
                            className={linkStyle.link}
                            onClick={() => {
                                window.location.assign(currentSite.pages.faq.url);
                            }}
                        >
                            {formatMessage({
                                id: 'global.footer.links.helpCenter',
                            })}
                        </span>
                    </li>
                ) : null}

                <li>
                    <span
                        className={linkStyle.link}
                        onClick={() => {
                            window.location.assign(generatePath('recruitment'));
                        }}
                    >
                        {formatMessage({
                            id: 'global.footer.links.recruitment',
                        })}
                    </span>
                </li>

                <li>
                    <span
                        className={linkStyle.link}
                        onClick={() => {
                            window.location.assign(generatePath('dmc'));
                        }}
                    >
                        {formatMessage({
                            id: 'global.footer.links.dmc',
                        })}
                    </span>
                </li>

                {hasFeature('jobs_working_abroad') ? (
                    <li>
                        <span
                            className={linkStyle.link}
                            onClick={() => {
                                window.location.assign(generatePath('jobs_working_abroad'));
                            }}
                        >
                            {formatMessage({
                                id: 'global.footer.links.workingAbroad',
                            })}
                        </span>
                    </li>
                ) : null}
            </ul>
        </>
    );
}
