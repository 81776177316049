import { SVGAttributes } from 'react';

type Props = SVGAttributes<SVGElement>;

export function IconTwitter(props: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            data-testid="IconTwitter"
            {...props}
        >
            <path d="M23.15 5.13024C22.3335 5.49276 21.4582 5.73617 20.5381 5.84665C21.4772 5.28388 22.1988 4.39311 22.5372 3.33317C21.6602 3.85279 20.6849 4.23084 19.6508 4.43454C18.8222 3.55069 17.6414 3 16.3346 3C13.8246 3 11.7893 5.03357 11.7893 7.54359C11.7893 7.8992 11.8307 8.24619 11.9084 8.57936C8.1313 8.38947 4.78402 6.58032 2.54158 3.83034C2.14971 4.5036 1.92702 5.28388 1.92702 6.11595C1.92702 7.69205 2.72801 9.08344 3.9485 9.89997C3.20274 9.8758 2.50187 9.67038 1.88904 9.3303C1.88904 9.34929 1.88904 9.36827 1.88904 9.38726C1.88904 11.59 3.45651 13.4251 5.53323 13.8445C5.15345 13.9481 4.75122 14.0034 4.33691 14.0034C4.04345 14.0034 3.75861 13.9757 3.48068 13.9205C4.06071 15.7245 5.73866 17.0416 7.72562 17.0779C6.17196 18.2966 4.21262 19.0217 2.08238 19.0217C1.71468 19.0217 1.35389 19.001 1 18.9578C3.0094 20.2491 5.39858 21 7.96557 21C16.3243 21 20.8955 14.0759 20.8955 8.07183C20.8955 7.87504 20.8903 7.67651 20.8834 7.48317C21.7707 6.84272 22.5406 6.04172 23.15 5.13024Z" />
        </svg>
    );
}
