'use client';

import cx from 'classnames';
import Image from 'next/image';

import { useIntl } from 'react-intl';

import { ImageCopyright } from '../../design-system/components/image/copyright/ImageCopyright';
import { Typography } from '../../design-system/components/typography/Typography';

import { imageLoaderForId } from '../../domains/Layout/Image/URL/ImageURL';
import { COVER_HEIGHT } from '../Cover/Cover';

import style from './ErrorPageCover.module.css';

const FIXED_COVER_ID = 1581688;
const FIXED_COVER_COPYRIGHT = 'Getty images';

type Props = {
    statusCode: number;
};

export function ErrorPageCover({ statusCode }: Props) {
    const { formatMessage } = useIntl();

    return (
        <div className={style.cover}>
            <div className="imageOverlay">
                <ImageCopyright copyright={FIXED_COVER_COPYRIGHT} />
                <Image
                    priority={true}
                    alt=""
                    src={FIXED_COVER_ID.toString()}
                    loader={imageLoaderForId({
                        fixedHeight: COVER_HEIGHT,
                    })}
                    fill
                    className="imageCover"
                />
            </div>
            <div className={cx('layout-with-centered-content', style.layout)}>
                <div className="main-container">
                    <div className={cx('row', style.row)}>
                        <div className="col-12">
                            <Typography
                                className={style.title}
                                as="h2"
                                scale="primary-xl-extra-bold"
                            >
                                {formatMessage({ id: 'page.error.message' }, { statusCode })}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
