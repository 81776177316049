'use client';

import cx from 'classnames';
import Image from 'next/image';
import { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

import { IconCompass } from '../../../../design-system/components/icons/Compass';
import { IconDiamond } from '../../../../design-system/components/icons/Diamond';
import { IconFace } from '../../../../design-system/components/icons/Face';
import { IconForest } from '../../../../design-system/components/icons/Forest';
import { IconSearchGlobe } from '../../../../design-system/components/icons/SearchGlobe';
import { useFeatureFlag } from '../../../../shared/hooks/useFeatureFlag';
import { useUrlGenerator } from '../../../../shared/hooks/useUrlGenerator';

import { imageLoaderForPath } from '../../Image/URL/ImageURL';

import style from './FooterBrand.module.css';

function PaymentLogo() {
    const { hasFeature } = useFeatureFlag();

    if (hasFeature('payment_ideal')) {
        return (
            <Image
                alt=""
                height={40}
                width={130}
                src="/partners/payment-ideal.png"
                loader={imageLoaderForPath}
            />
        );
    }

    if (hasFeature('payment_american')) {
        return (
            <Image
                alt=""
                height={40}
                width={130}
                src="/partners/payment-american.png"
                loader={imageLoaderForPath}
            />
        );
    }

    if (hasFeature('payment_postepay')) {
        return (
            <Image
                alt=""
                height={40}
                width={130}
                src="/partners/payment-postepay.png"
                loader={imageLoaderForPath}
            />
        );
    }

    return (
        <Image
            alt=""
            height={40}
            width={130}
            src="/partners/payment.png"
            loader={imageLoaderForPath}
        />
    );
}

function AssuranceLogo() {
    const { hasFeature } = useFeatureFlag();

    if (hasFeature('guarantees_asta')) {
        return (
            <Image
                alt="Asta logo"
                height={50}
                width={72}
                src="/partners/asta-blue.png"
                loader={imageLoaderForPath}
            />
        );
    }

    return (
        <Image
            alt="Axa logo"
            height={50}
            width={50}
            src="/partners/axa-color.png"
            loader={imageLoaderForPath}
        />
    );
}

type ItemProps = {
    title: string;
    href: string;
};

function FooterItem({ title, children, href }: PropsWithChildren<ItemProps>) {
    return (
        <a href={href} className={cx('font-xxs-regular', style.item)}>
            <div className={style.imageWrapper}>{children}</div>
            {title}
        </a>
    );
}

export function FooterBrand() {
    const { generatePath, generateBrandUrl } = useUrlGenerator();
    const { hasFeature } = useFeatureFlag();
    const { formatMessage } = useIntl();

    return (
        <div className={style.container}>
            <div className={style.section}>
                <div className={cx(style.title, 'font-m-extrabold')}>
                    {formatMessage({ id: 'global.footer.brand.promises.title' })}
                </div>
                <div className={style.list}>
                    <FooterItem
                        title={formatMessage({ id: 'global.footer.brand.promises.concept' })}
                        href={generateBrandUrl('concept')}
                    >
                        <IconSearchGlobe height={40} width={40} />
                    </FooterItem>
                    <FooterItem
                        title={formatMessage({
                            id: 'global.footer.brand.promises.betterTrips',
                        })}
                        href={generateBrandUrl('better_trips')}
                    >
                        <IconCompass height={40} width={40} />
                    </FooterItem>
                    <FooterItem
                        title={formatMessage({ id: 'global.footer.brand.promises.whoWeAre' })}
                        href={generateBrandUrl('concept')}
                    >
                        <IconFace height={40} width={40} />
                    </FooterItem>
                </div>
            </div>
            <div className={style.section}>
                <div className={cx(style.title, 'font-m-extrabold')}>
                    {formatMessage({ id: 'global.footer.brand.guarantees.title' })}
                </div>
                <div className={style.list}>
                    <FooterItem
                        title={formatMessage({
                            id: 'global.footer.brand.guarantees.selection',
                        })}
                        href={generateBrandUrl('agency_page')}
                    >
                        <IconDiamond height={40} width={40} />
                    </FooterItem>
                    <FooterItem
                        title={formatMessage({
                            id: 'global.footer.brand.guarantees.carbonNeutral',
                        })}
                        href={generateBrandUrl('carbon_neutral')}
                    >
                        <IconForest height={40} width={40} />
                    </FooterItem>
                    <FooterItem
                        title={formatMessage({
                            id: 'global.footer.brand.guarantees.assurance',
                        })}
                        href={generatePath(hasFeature('guarantees') ? 'guarantees' : 'assurance')}
                    >
                        <AssuranceLogo />
                    </FooterItem>
                    <FooterItem
                        title={formatMessage({
                            id: 'global.footer.brand.guarantees.payment',
                        })}
                        href={generatePath('guarantees')}
                    >
                        <PaymentLogo />
                    </FooterItem>
                </div>
            </div>
        </div>
    );
}
