'use client';

import cx from 'classnames';
import Image from 'next/image';
import { PropsWithChildren } from 'react';

import { Partner } from '../../../../core/Site/Site';
import { Anchor } from '../../../../design-system/components/anchor/Anchor';

import { useCurrentSite } from '../../../../shared/providers/currentSite/useCurrentSite';
import { imageLoaderForPath } from '../../Image/URL/ImageURL';

import { FooterSocialNetworks } from '../FooterSocialNetworks/FooterSocialNetworks';

import style from './FooterPartners.module.css';

type LinkProps = {
    partner: Partner;
};

function Link({ partner, children }: PropsWithChildren<LinkProps>) {
    if (!partner.url) {
        return <div className={style.imageWrapper}>{children}</div>;
    }

    return (
        <Anchor obfuscate className={style.imageWrapper} href={partner.url}>
            {children}
        </Anchor>
    );
}

export function FooterLogoGrid() {
    const { currentSite } = useCurrentSite();

    return (
        <div className={style.wrapper}>
            <div className={style.section}>
                {currentSite.partners.base.map((partner) => {
                    return (
                        <Link key={partner.name} partner={partner}>
                            <Image
                                alt={partner.name}
                                height={partner.logo.height}
                                width={partner.logo.width}
                                src={partner.logo.src}
                                loader={imageLoaderForPath}
                            />
                        </Link>
                    );
                })}
            </div>
            <div className={cx(style.section, style.sustainability)}>
                {currentSite.partners.sustainability.map((partner) => {
                    return (
                        <Link key={partner.name} partner={partner}>
                            <Image
                                alt={partner.name}
                                height={partner.logo.height}
                                width={partner.logo.width}
                                src={partner.logo.src}
                                loader={imageLoaderForPath}
                            />
                        </Link>
                    );
                })}
            </div>
            <div className={style.section}>
                <FooterSocialNetworks />
            </div>
        </div>
    );
}
