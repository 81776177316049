import { SVGAttributes } from 'react';

type Props = SVGAttributes<SVGElement>;

export function IconVisa(props: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="4 0 18 18"
            fill="currentColor"
            data-testid="IconVisa"
            {...props}
        >
            <path d="M 10.230469 5.339844 L 8.46875 9.667969 L 7.320312 9.667969 L 6.457031 6.214844 C 6.402344 6.003906 6.359375 5.925781 6.195312 5.835938 C 5.9375 5.6875 5.503906 5.550781 5.125 5.46875 L 5.152344 5.339844 L 7 5.339844 C 7.234375 5.339844 7.449219 5.503906 7.5 5.78125 L 7.957031 8.289062 L 9.089844 5.339844 Z M 14.726562 8.257812 C 14.730469 7.113281 13.195312 7.050781 13.203125 6.539062 C 13.207031 6.386719 13.351562 6.21875 13.664062 6.179688 C 13.820312 6.15625 14.25 6.140625 14.734375 6.371094 L 14.925781 5.453125 C 14.664062 5.355469 14.328125 5.261719 13.910156 5.261719 C 12.835938 5.261719 12.082031 5.851562 12.074219 6.691406 C 12.066406 7.316406 12.613281 7.664062 13.027344 7.871094 C 13.449219 8.082031 13.589844 8.21875 13.589844 8.410156 C 13.585938 8.699219 13.25 8.828125 12.9375 8.832031 C 12.394531 8.839844 12.078125 8.679688 11.824219 8.558594 L 11.628906 9.507812 C 11.878906 9.628906 12.347656 9.730469 12.835938 9.738281 C 13.976562 9.738281 14.722656 9.15625 14.726562 8.257812 Z M 17.5625 9.671875 L 18.566406 9.671875 L 17.6875 5.339844 L 16.761719 5.339844 C 16.554688 5.339844 16.378906 5.464844 16.300781 5.65625 L 14.671875 9.667969 L 15.8125 9.667969 L 16.039062 9.023438 L 17.429688 9.023438 Z M 16.351562 8.136719 L 16.921875 6.511719 L 17.25 8.136719 Z M 11.78125 5.339844 L 10.882812 9.667969 L 9.796875 9.667969 L 10.695312 5.339844 Z M 11.78125 5.339844 " />
        </svg>
    );
}
