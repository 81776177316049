'use client';

import cx from 'classnames';
import { useIntl } from 'react-intl';

import { useFeatureFlag } from '../../../../../shared/hooks/useFeatureFlag';
import { useUrlGenerator } from '../../../../../shared/hooks/useUrlGenerator';
import { useCurrentSite } from '../../../../../shared/providers/currentSite/useCurrentSite';

import linkStyle from '../FooterLinkList.module.css';

export function FooterLinksCommunication() {
    const { currentSite } = useCurrentSite();
    const { hasFeature } = useFeatureFlag();
    const { formatMessage } = useIntl();
    const { generatePath, generateMobileApplicationUrl, generatePathMayNotExist } =
        useUrlGenerator();
    const { pages } = currentSite;
    const partnerServicesUrl = generatePathMayNotExist('partners');

    return (
        <>
            <span className={cx(linkStyle.linkTitle, 'font-m-extrabold')}>
                {formatMessage({ id: 'global.footer.links.communication' })}
            </span>

            <ul className="font-body-regular">
                <li>
                    <span
                        className={linkStyle.link}
                        onClick={() => {
                            window.location.assign(generatePath('magazine'));
                        }}
                    >
                        {formatMessage({ id: 'global.footer.links.magazine' })}
                    </span>
                </li>

                {pages.partner.url ? (
                    <li>
                        <span
                            className={linkStyle.link}
                            onClick={() => {
                                window.location.assign(pages.partner.url);
                            }}
                        >
                            {formatMessage({
                                id: 'global.footer.links.partners',
                            })}
                        </span>
                    </li>
                ) : null}

                <li>
                    <span
                        className={linkStyle.link}
                        onClick={() => {
                            const routeName = hasFeature('press_pages')
                                ? 'press'
                                : 'press_article_deprecated';

                            window.location.assign(generatePath(routeName));
                        }}
                    >
                        {formatMessage({
                            id: 'global.footer.links.press',
                        })}
                    </span>
                </li>

                {partnerServicesUrl ? (
                    <li>
                        <span
                            className={linkStyle.link}
                            onClick={() => {
                                window.location.assign(partnerServicesUrl);
                            }}
                        >
                            {formatMessage({
                                id: 'global.footer.links.partnerServices',
                            })}
                        </span>
                    </li>
                ) : null}

                {hasFeature('mobile_applications') ? (
                    <>
                        <li>
                            <span
                                className={linkStyle.link}
                                onClick={() => {
                                    window.open(
                                        generateMobileApplicationUrl('ios'),
                                        '_blank',
                                        'noopener',
                                    );
                                }}
                            >
                                {formatMessage({
                                    id: 'global.footer.links.iOS',
                                })}
                            </span>
                        </li>
                        <li>
                            <span
                                className={linkStyle.link}
                                onClick={() => {
                                    window.open(
                                        generateMobileApplicationUrl('android'),
                                        '_blank',
                                        'noopener',
                                    );
                                }}
                            >
                                {formatMessage({
                                    id: 'global.footer.links.android',
                                })}
                            </span>
                        </li>
                    </>
                ) : null}
            </ul>
        </>
    );
}
