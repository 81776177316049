import cx from 'classnames';
import { AnchorHTMLAttributes, PropsWithChildren } from 'react';

export type AnchorProps = PropsWithChildren<
    AnchorHTMLAttributes<HTMLAnchorElement> & {
        obfuscate?: boolean;
        href: string;
    }
>;

export function Anchor({ className, obfuscate, ...props }: AnchorProps) {
    const HTMLTag = obfuscate ? 'span' : 'a';

    return (
        <HTMLTag
            className={cx('link', className)}
            {...props}
            href={obfuscate ? undefined : props.href}
            onClick={(event) => {
                if (typeof props.onClick === 'function') {
                    props.onClick(event);
                }

                if (obfuscate) {
                    if (props.target === '_blank' || event.ctrlKey) {
                        return window.open(props.href, '_blank', 'noopener');
                    }
                    window.location.assign(props.href);
                }
            }}
        >
            {props.children}
        </HTMLTag>
    );
}
