import cx from 'classnames';
import Image, { ImageProps } from 'next/image';

import { ImageCopyright } from '../../design-system/components/image/copyright/ImageCopyright';

import style from './Cover.module.css';

type Props = {
    title: string;
    subTitle: string;
    image: ImageProps;
    copyright?: string;
};

export const COVER_HEIGHT = 460;

export function Cover({ title, subTitle, image, copyright }: Props) {
    return (
        <div className={style.cover}>
            <div className="imageOverlay">
                {copyright ? <ImageCopyright copyright={copyright} /> : null}
                <Image
                    priority={true}
                    {...image}
                    alt={image.alt}
                    fill
                    className={cx(image.className, 'imageCover')}
                />
            </div>
            <div className={cx('layout-with-centered-content', style.layout)}>
                <div className="main-container">
                    <div className={cx('row', style.row)}>
                        <div className="col-12">
                            <h1 className="font-l-extrabold text-shadow">{title}</h1>
                            <h2 className={cx('font-secondary-m-bold text-shadow', style.title)}>
                                {subTitle}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
