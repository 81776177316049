import * as React from 'react';

import { IconCopyright } from '../../icons/Copyright';

import style from './ImageCopyright.module.css';

interface Props {
    copyright: string;
}

export function ImageCopyright({ copyright }: Props) {
    return (
        <div className={style.copyright}>
            <div className={style.symbolBackground}>
                <IconCopyright className={style.symbol} />
            </div>
            <div className={style.author}>{copyright}</div>
        </div>
    );
}
