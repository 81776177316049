'use client';

import { useIntl } from 'react-intl';

import { EvaneosLogo } from '../../../../design-system/components/illustration/EvaneosLogo';

import { useCurrentSite } from '../../../../shared/providers/currentSite/useCurrentSite';

import style from './FooterLogo.module.css';

export function FooterLogo() {
    const { formatMessage } = useIntl();
    const { currentSite } = useCurrentSite();

    return (
        <div className={style.wrapper}>
            <div className={style.footerCopyright}>
                {currentSite.lang !== 'en' ? (
                    <>
                        <p className="font-xs-regular">
                            {formatMessage({ id: 'global.footer.localHeroTranslation' })}
                        </p>
                        <p className="font-xs-regular">
                            {formatMessage({ id: 'global.footer.betterTripsTranslation' })}
                        </p>
                    </>
                ) : null}
                <p>© Evaneos</p>
            </div>
            <EvaneosLogo cut className={style.logo} />
        </div>
    );
}
