import cx from 'classnames';
import { Dispatch, HTMLProps, MouseEventHandler, SelectHTMLAttributes } from 'react';

import { IconChevronDown } from '../../icons/ChevronDown';

import style from './Select.module.css';

export type SelectProps<T = string> = {
    label?: string;
    description?: string;
    placeholder?: string;
    error?: string;
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLLabelElement>;
    allowEmptySelection?: boolean; // TODO: test this
    options: HTMLProps<HTMLOptionElement>[];
    selectProps?: Omit<SelectHTMLAttributes<HTMLSelectElement>, 'defaultValue'> & {
        'data-testid'?: string;
    };
    className?: string;
} & (
    | {
          setSelected: Dispatch<T>;
          selected: T;
      }
    | {
          setSelected?: never;
          selected?: never;
      }
);

export function Select<T extends SelectHTMLAttributes<HTMLSelectElement>['value'] = string>({
    label,
    description,
    placeholder,
    error,
    disabled,
    onClick,
    allowEmptySelection = false,
    options,
    setSelected,
    selected,
    selectProps = {},
    className,
}: SelectProps<T>) {
    return (
        <div className={cx(style.wrapper, className)}>
            <label onClick={onClick}>
                {label || description ? (
                    <div className={style.head}>
                        <div className="font-body-bold">{label}</div>
                        <div className="font-xs-regular">{description}</div>
                    </div>
                ) : null}
                <div
                    className={cx(style.selectWrapper, {
                        [style.selectError]: !!error,
                        [style.selectDisabled]: !!disabled,
                    })}
                >
                    <span className={style.selectArrow}>
                        <IconChevronDown />
                    </span>
                    <select
                        {...selectProps}
                        className={cx(style.select, selectProps.className, 'font-xs-regular')}
                        value={setSelected === undefined ? selectProps.value : selected}
                        disabled={disabled}
                        onChange={(event) => {
                            const value = event.target.value as T;
                            if (setSelected) {
                                setSelected(value);
                            }
                            if (selectProps.onChange) {
                                selectProps.onChange(event);
                            }
                        }}
                    >
                        {placeholder ? (
                            <option
                                value=""
                                disabled={!allowEmptySelection}
                                hidden={!allowEmptySelection}
                            >
                                {placeholder}
                            </option>
                        ) : null}
                        {options.map((option) => (
                            <option key={option.value?.toString()} {...option}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                {error ? (
                    <div className={cx(style.textError, 'font-xs-regular')}>{error}</div>
                ) : null}
            </label>
        </div>
    );
}
