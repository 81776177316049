import { SVGAttributes } from 'react';

type Props = SVGAttributes<SVGElement>;

export function IconCopyright(props: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            data-testid="IconCopyright"
            {...props}
        >
            <path d="M11.9016 22C17.5082 22 21.8361 17.377 21.8361 12C21.8361 6.59016 17.541 2 11.9672 2C6.36066 2 2 6.59016 2 12C2 17.4426 6.36066 22 11.9016 22ZM11.9016 20.0984C7.37705 20.0984 3.90164 16.459 3.90164 12.0328C3.90164 7.60656 7.37705 3.90164 11.9672 3.90164C16.459 3.90164 19.9344 7.54098 19.9344 12C19.9344 16.4262 16.4262 20.0984 11.9016 20.0984ZM12.1967 17.4098C14.0984 17.4098 15.4754 16.5574 16.3607 15.4098L15.082 14.3279C14.459 15.1475 13.4426 15.7049 12.1967 15.7049C10.1311 15.7049 8.65574 14.3279 8.65574 12.0656C8.65574 9.83607 10.0328 8.36066 12.1967 8.36066C13.3443 8.36066 14.3607 8.91803 15.082 9.80328L16.3607 8.72131C15.2131 7.27869 13.6721 6.68852 12.1967 6.68852C8.72131 6.68852 6.85246 9.11475 6.85246 12.0656C6.85246 15.5738 9.31148 17.4098 12.1967 17.4098Z" />
        </svg>
    );
}
