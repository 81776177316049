import { SVGAttributes } from 'react';

type Props = SVGAttributes<SVGElement>;

export function IconAmericanExpress(props: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            data-testid="IconAmericanExpress"
            {...props}
        >
            <path d="M 19.617188 10.773438 L 18.960938 10.773438 L 18.011719 9.238281 L 18.011719 10.511719 L 18.007812 10.511719 L 18.007812 10.777344 L 17.503906 10.777344 L 17.503906 10.773438 L 16.996094 10.773438 L 16.820312 10.339844 L 15.777344 10.339844 L 15.597656 10.804688 L 15.03125 10.804688 C 14.792969 10.804688 14.496094 10.746094 14.316406 10.570312 C 14.140625 10.398438 14.050781 10.164062 14.050781 9.789062 C 14.050781 9.5 14.109375 9.207031 14.316406 8.976562 C 14.464844 8.800781 14.734375 8.742188 15.0625 8.742188 L 15.539062 8.742188 L 15.539062 9.179688 L 15.0625 9.179688 C 14.882812 9.179688 14.792969 9.207031 14.675781 9.296875 C 14.585938 9.382812 14.527344 9.554688 14.527344 9.757812 C 14.527344 9.992188 14.554688 10.136719 14.675781 10.253906 C 14.765625 10.339844 14.882812 10.367188 15.03125 10.367188 L 15.242188 10.367188 L 15.925781 8.773438 L 16.667969 8.773438 L 17.503906 10.714844 L 17.503906 8.773438 L 18.25 8.773438 L 19.113281 10.195312 L 19.113281 8.773438 L 19.617188 8.773438 Z M 16.3125 9.09375 L 15.957031 9.90625 L 16.640625 9.90625 Z M 16.3125 9.09375 " />
            <path d="M 8.628906 10.773438 L 8.152344 10.773438 L 8.152344 9.179688 L 8.125 9.246094 L 7.429688 10.773438 L 6.992188 10.773438 L 6.277344 9.179688 L 6.277344 10.773438 L 5.265625 10.773438 L 5.058594 10.339844 L 4.042969 10.339844 L 3.835938 10.773438 L 3.3125 10.773438 L 4.191406 8.742188 L 4.9375 8.742188 L 5.769531 10.6875 L 5.769531 8.742188 L 6.558594 8.742188 L 6.574219 8.773438 L 6.949219 9.570312 L 7.222656 10.167969 L 7.230469 10.136719 L 7.824219 8.742188 L 8.628906 8.742188 Z M 4.222656 9.90625 L 4.878906 9.90625 L 4.550781 9.09375 Z M 4.222656 9.90625 " />
            <path d="M 10.683594 9.179688 L 9.523438 9.179688 L 9.523438 9.554688 L 10.65625 9.554688 L 10.65625 9.960938 L 9.523438 9.960938 L 9.523438 10.367188 L 10.683594 10.367188 L 10.683594 10.804688 L 9.015625 10.804688 L 9.015625 8.742188 L 10.683594 8.742188 Z M 10.683594 9.179688 " />
            <path d="M 12.808594 9.925781 L 12.8125 9.929688 C 12.824219 9.941406 12.832031 9.953125 12.839844 9.964844 C 12.898438 10.035156 12.945312 10.144531 12.945312 10.304688 L 12.945312 10.320312 C 12.949219 10.335938 12.949219 10.351562 12.949219 10.367188 L 12.949219 10.773438 L 12.5 10.773438 L 12.5 10.542969 C 12.5 10.425781 12.5 10.253906 12.414062 10.136719 C 12.382812 10.109375 12.355469 10.089844 12.324219 10.078125 C 12.28125 10.050781 12.195312 10.050781 12.054688 10.050781 L 11.519531 10.050781 L 11.519531 10.773438 L 11.011719 10.773438 L 11.011719 8.746094 L 12.144531 8.746094 C 12.414062 8.746094 12.589844 8.746094 12.742188 8.832031 C 12.886719 8.917969 12.972656 9.058594 12.976562 9.28125 C 12.972656 9.589844 12.765625 9.761719 12.621094 9.816406 C 12.621094 9.816406 12.71875 9.835938 12.808594 9.925781 Z M 12.382812 9.589844 C 12.390625 9.582031 12.398438 9.570312 12.40625 9.558594 C 12.433594 9.515625 12.464844 9.441406 12.449219 9.339844 C 12.449219 9.332031 12.445312 9.320312 12.445312 9.3125 L 12.441406 9.308594 C 12.429688 9.257812 12.390625 9.226562 12.351562 9.207031 C 12.292969 9.179688 12.203125 9.179688 12.113281 9.179688 L 11.519531 9.179688 L 11.519531 9.644531 L 12.113281 9.644531 C 12.203125 9.644531 12.292969 9.644531 12.351562 9.613281 C 12.363281 9.609375 12.371094 9.605469 12.378906 9.597656 C 12.378906 9.597656 12.378906 9.59375 12.382812 9.589844 Z M 12.382812 9.589844 " />
            <path d="M 13.8125 10.773438 L 13.304688 10.773438 L 13.304688 8.742188 L 13.8125 8.742188 Z M 13.8125 10.773438 " />
            <path d="M 11.996094 13.5 L 11.398438 13.5 L 11.398438 14.257812 L 10.421875 14.257812 L 9.851562 13.617188 L 9.847656 13.621094 L 9.820312 13.589844 L 9.167969 14.257812 L 7.261719 14.257812 L 7.261719 12.226562 L 9.195312 12.226562 L 9.722656 12.789062 L 9.835938 12.90625 L 9.851562 12.890625 L 10.476562 12.226562 L 12.054688 12.226562 C 12.359375 12.226562 12.703125 12.296875 12.832031 12.601562 C 12.847656 12.664062 12.859375 12.730469 12.859375 12.804688 C 12.859375 13.386719 12.441406 13.5 11.992188 13.5 Z M 12.351562 12.890625 C 12.324219 12.71875 12.203125 12.632812 12.023438 12.632812 L 11.398438 12.632812 L 11.398438 13.152344 L 12.054688 13.152344 C 12.234375 13.152344 12.351562 13.066406 12.351562 12.890625 Z M 10.894531 14.082031 L 10.894531 12.429688 L 10.117188 13.242188 Z M 7.707031 12.632812 L 7.707031 13.007812 L 8.808594 13.007812 L 8.808594 13.414062 L 7.707031 13.414062 L 7.707031 13.851562 L 8.929688 13.851562 L 9.492188 13.242188 L 8.957031 12.632812 Z M 7.707031 12.632812 " />
            <path d="M 14.972656 13.472656 C 15.03125 13.558594 15.0625 13.675781 15.0625 13.851562 L 15.0625 14.257812 L 14.554688 14.257812 L 14.554688 13.996094 C 14.554688 13.878906 14.554688 13.675781 14.464844 13.589844 C 14.40625 13.5 14.289062 13.5 14.109375 13.5 L 13.574219 13.5 L 13.574219 14.257812 L 13.066406 14.257812 L 13.066406 12.195312 L 14.199219 12.195312 C 14.4375 12.195312 14.644531 12.195312 14.792969 12.285156 C 14.941406 12.371094 15.0625 12.515625 15.0625 12.746094 C 15.0625 13.066406 14.851562 13.242188 14.703125 13.296875 C 14.851562 13.355469 14.941406 13.414062 14.972656 13.472656 Z M 14.4375 13.09375 C 14.496094 13.066406 14.527344 12.980469 14.527344 12.890625 C 14.554688 12.777344 14.496094 12.71875 14.4375 12.691406 C 14.378906 12.660156 14.289062 12.660156 14.199219 12.660156 L 13.601562 12.660156 L 13.601562 13.125 L 14.199219 13.125 C 14.289062 13.125 14.378906 13.125 14.4375 13.09375 Z M 14.4375 13.09375 " />
            <path d="M 17.027344 12.632812 L 15.867188 12.632812 L 15.867188 13.007812 L 16.996094 13.007812 L 16.996094 13.414062 L 15.867188 13.414062 L 15.867188 13.820312 L 17.027344 13.820312 L 17.027344 14.257812 L 15.359375 14.257812 L 15.359375 12.195312 L 17.027344 12.195312 Z M 17.027344 12.632812 " />
            <path d="M 18.277344 14.257812 L 17.324219 14.257812 L 17.324219 13.820312 L 18.277344 13.820312 C 18.367188 13.820312 18.425781 13.820312 18.484375 13.761719 C 18.515625 13.734375 18.546875 13.675781 18.546875 13.617188 C 18.546875 13.558594 18.515625 13.5 18.484375 13.472656 C 18.457031 13.445312 18.394531 13.414062 18.308594 13.414062 C 17.832031 13.386719 17.265625 13.414062 17.265625 12.777344 C 17.265625 12.488281 17.445312 12.167969 17.980469 12.167969 L 18.960938 12.167969 L 18.960938 12.660156 L 18.039062 12.660156 C 17.949219 12.660156 17.890625 12.660156 17.832031 12.691406 C 17.773438 12.71875 17.773438 12.777344 17.773438 12.835938 C 17.773438 12.921875 17.832031 12.949219 17.890625 12.980469 C 17.949219 13.007812 18.011719 13.007812 18.070312 13.007812 L 18.335938 13.007812 C 18.605469 13.007812 18.785156 13.066406 18.902344 13.183594 C 18.992188 13.269531 19.050781 13.414062 19.050781 13.617188 C 19.050781 14.054688 18.785156 14.257812 18.277344 14.257812 Z M 18.277344 14.257812 " />
            <path d="M 20.839844 14.054688 C 20.71875 14.167969 20.511719 14.257812 20.214844 14.257812 L 19.261719 14.257812 L 19.261719 13.820312 L 20.214844 13.820312 C 20.300781 13.820312 20.363281 13.820312 20.421875 13.761719 C 20.453125 13.734375 20.480469 13.675781 20.480469 13.617188 C 20.480469 13.558594 20.453125 13.5 20.421875 13.472656 C 20.390625 13.445312 20.332031 13.414062 20.242188 13.414062 C 19.765625 13.386719 19.199219 13.414062 19.199219 12.777344 C 19.199219 12.5 19.363281 12.25 19.761719 12.175781 C 19.808594 12.171875 19.859375 12.167969 19.914062 12.167969 L 20.898438 12.167969 L 20.898438 12.660156 L 19.976562 12.660156 C 19.886719 12.660156 19.828125 12.660156 19.765625 12.6875 C 19.738281 12.71875 19.707031 12.777344 19.707031 12.835938 C 19.707031 12.921875 19.738281 12.949219 19.828125 12.980469 C 19.886719 13.007812 19.945312 13.007812 20.003906 13.007812 L 20.273438 13.007812 C 20.402344 13.007812 20.5 13.023438 20.589844 13.054688 C 20.675781 13.082031 20.945312 13.203125 21.007812 13.511719 C 21.011719 13.546875 21.015625 13.578125 21.015625 13.617188 C 21.015625 13.792969 20.957031 13.9375 20.839844 14.050781 Z M 20.839844 14.054688 " />
        </svg>
    );
}
