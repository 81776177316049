import { FunctionComponent, SVGAttributes } from 'react';

import { SocialNetworkName } from '../../../../core/Site/Site';
import { IconFacebook } from '../../../../design-system/components/icons/Facebook';
import { IconInstagram } from '../../../../design-system/components/icons/Instagram';
import { IconLinkedIn } from '../../../../design-system/components/icons/Linkedin';
import { IconTwitter } from '../../../../design-system/components/icons/Twitter';
import { IconYouTube } from '../../../../design-system/components/icons/YouTube';

import { useCurrentSite } from '../../../../shared/providers/currentSite/useCurrentSite';

import style from './FooterSocialNetworks.module.css';

export function FooterSocialNetworks() {
    const {
        currentSite: { socialNetworks },
    } = useCurrentSite();

    const availableSocialNetworks: Array<{
        name: SocialNetworkName;
        Icon: FunctionComponent<SVGAttributes<SVGElement>>;
    }> = [
        {
            name: 'facebook',
            Icon: IconFacebook,
        },
        {
            name: 'twitter',
            Icon: IconTwitter,
        },
        {
            name: 'instagram',
            Icon: IconInstagram,
        },
        {
            name: 'youtube',
            Icon: IconYouTube,
        },
        {
            name: 'linkedin',
            Icon: IconLinkedIn,
        },
    ];

    return (
        <div className={style.footerSocials}>
            {availableSocialNetworks
                .filter((socialNetwork) => {
                    return socialNetworks[socialNetwork.name].url !== '';
                })
                .map((socialNetwork) => (
                    <a
                        key={socialNetwork.name}
                        data-testid={socialNetwork.name}
                        className={style.link}
                        href={socialNetworks[socialNetwork.name].url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <socialNetwork.Icon className={style.icon} />
                    </a>
                ))}
        </div>
    );
}
